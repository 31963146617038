import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { TbWorldWww, TbBrandInstagram } from 'react-icons/tb';
import { BsFacebook, BsTrashFill, BsPlusCircle } from 'react-icons/bs';

import { useCategories } from '../context/CategoriesContext.js';
import { useUser } from '../context/UserContext.js';
import { useBusiness } from '../context/BusinessContext.js';

import { addBusiness, uploadBusinessAssets, updateBusinessData } from '../services/ziti.business.js';

import { GetArrayPositionFromString, weekday, compareDay } from '../utils.js';

import ComboBox from '../components/ComboBox.js';
import SelectScheduleModal from '../components/SelectScheduleModal.js';
import ImageUploadPreview from '../components/ImageUploaderPreview.js';
import UploadAssetModalResult from "../components/UploadAssetModalResult.js";
import Loading from '../components/Loading.js';

const AddBusiness = () => {
    const [step, setStep] = useState(0);
    const [completedStep, setCompletedStep] = useState(-1);
    const [userName, setUserName] = useState('');
    const [pwd, setPwd] = useState('');
    const [pwd2, setPwd2] = useState('');
    const [bName, setbName] = useState('');
    const [bPhone, setbPhone] = useState(0);
    const [bEmail, setbEmail] = useState('');
    const [bAddress, setbAddress] = useState('');
    const [bLat, setbLat] = useState('');
    const [bLon, setbLon] = useState('');
    const [bSchedule, setbSchedule] = useState([]);// {day:Int, hStart: String, hClose: String}
    const [bCategoryId, setbCategoryID] = useState('');
    const [bCategoryText, setbCategoryText] = useState('');
    const [subCatArray, setsubCatArray] = useState([]);
    const [bSubCategoryId, setbSubCategoryID] = useState('');
    const [bSubCategoryText, setbSubCategoryText] = useState('');
    const [bLinks, setbLinks] = useState({}); 
    const [bAssets, setbAssets] = useState({});
    const [errorCode, setErrorCode] = useState(-1);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorAssets, setErrorAssets] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const { categories, isLoadingCategories, setCategoriesData } = useCategories();
    const { addNewBusiness, isUploadingAssets, setUploadingAssets, business, setBusinessData } = useBusiness();
    const { user } = useUser();

    const toggleStep = (index) => {
        if(index <= completedStep || index === (completedStep + 1)){
            setStep(index);
        }
    };

    const checkUserName = () => {
        return business.find((element) => element.userName === userName);
    }

    const checkEmail = () => {
        return business.find((element) => element.email ===bEmail);
    }

    const nextStep = () => {
        switch(step){
            case 0:
                if(userName === ''){ setErrorCode(1); setErrorMessage('El nombre de usuario es requerido.'); return; }
                else if(checkUserName()){ setErrorCode(1); setErrorMessage('Este usuario ya existe.'); return; }
                else if(pwd === ''){ setErrorCode(1); setErrorMessage('La contraseña es requerida.'); return; }
                else if(pwd !== pwd2){ setErrorCode(1); setErrorMessage('Las contraseñas no coinciden.'); return; }
                else {
                    setStep(1); setCompletedStep(0);
                }
                break;
            case 1:
                if(bName ==='') { setErrorCode(2); setErrorMessage('El Nombre es requerido.');  return; }
                else if( bName.length <= 2) { setErrorCode(2); setErrorMessage('El Nombre es demasiado corto.');  return; }
                else if( bPhone.length < 10) { setErrorCode(2); setErrorMessage('El Telefono debe tener 10 digitos.');  return; }
                else if( bEmail.length < 7) { setErrorCode(2); setErrorMessage('El correo es obligatorio.'); return; }
                else if( checkEmail() ) { setErrorCode(2); setErrorMessage('Este correo ya esta siendo utilizado'); return; }
                else if( bAddress.length < 8) { setErrorCode(2); setErrorMessage('La direccion es obligatoria.'); return; }
                else { 
                    setStep(2); setCompletedStep(1); 
                }
                break;
            case 2:
                if(bCategoryId.length === 0) { setErrorCode(3); setErrorMessage('La categoria es requerida');  return; }
                else if(bSubCategoryId.length === 0) { setErrorCode(3); setErrorMessage('La sub categoria es requerida');  return; }
                else { 
                    setStep(3); setCompletedStep(2); 
                }
                break;
            case 3:
                if(bSchedule.length < 1) { setErrorCode(4); setErrorMessage('Agrega almenos un horario'); return; }
                else{
                    setStep(4); setCompletedStep(3);
                }
                break;
            case 4:
                setStep(5); setCompletedStep(4);
                break;
            default:
                break;
        }

        setErrorCode(0);
        setErrorMessage('');
    }

    const saveAsset = (asset) => {
        setbAssets({
            ...bAssets,
            [asset.name]:asset.src
        });
    };

    const steps = [
        {
          title: 'Login',
          content: 
            <div class="items-center flex flex-col">
                <div class="w-full items-center flex flex-col">
                    <div class="flex flex-row w-[60%] items-baseline">
                        <label for="username" class="block mb-4 text-lg font-medium text-gray-800">Usuario</label>
                        <input id="username" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={30}
                            value={userName} onChange={ e => setUserName(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[60%] items-baseline">
                        <label for="username" class="block mb-4 text-lg font-medium text-gray-800">Contraseña</label>
                        <input id="username" type="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={30}
                            value={pwd} onChange={ e => setPwd(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[60%] items-baseline">
                        <label for="username" class="block mb-4 text-lg font-medium text-gray-800">Repetir Contraseña</label>
                        <input id="username" type="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={30}
                            value={pwd2} onChange={ e => setPwd2(e.target.value) }></input>
                    </div>
                </div>
                <div class="mt-5 flex flex-col w-full items-center">
                    { errorCode === 1  && (
                        <div class="bg-red-200 mb-8 w-[50%] text-center rounded-md border border-red-600 h-[20%]">
                            <p class="text-red-600 text-lg text-center">{errorMessage}</p>
                        </div>
                    )}
                    
                    <button
                        type="button"
                        class=" float-right mr-[10%] inline-block rounded bg-green-600 px-6 pb-2 pt-2.5 text-s font-medium 
                        leading-normal text-white shadow-md transition duration-150 ease-in-out 
                        hover:bg-primary-600 hover:shadow-xl"
                        onClick={() => nextStep()}>
                        Siguiente
                    </button>
                </div>
            </div>,
        },
        {
          title: 'Informacion',
          content: 
            <div class="items-center flex flex-col">
                <div class="w-full items-center flex flex-col">
                    <div class="flex flex-row w-[100%] items-baseline">
                        <label for="businessName" class="block mb-4 text-lg font-medium text-gray-800">Nombre del Negocio</label>
                        <input id="businessName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={50}
                            value={bName} onChange={ e => setbName(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[100%] items-baseline">
                        <label for="businessPhone" class="block mb-4 text-lg font-medium text-gray-800">Telefono</label>
                        <input id="businessPhone" type="tel" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={10}
                            value={bPhone===0?'':bPhone} onChange={ e => setbPhone(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[100%] items-baseline">
                        <label for="businessEmail" class="block mb-4 text-lg font-medium text-gray-800">Email</label>
                        <input id="businessEmail" type="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={100}
                            value={bEmail} onChange={ e => setbEmail(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[100%] items-baseline">
                        <label for="businessAddress" class="block mb-4 text-lg font-medium text-gray-800">Direccion</label>
                        <input id="businessAddress" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={250}
                            value={bAddress} onChange={ e => setbAddress(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[100%] items-baseline">
                        <label for="businessLat" class="block mb-4 text-lg font-medium text-gray-800">Latitud</label>
                        <input id="businessLat"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={30}
                            value={bLat} onChange={ e => setbLat(e.target.value) }></input>
                    </div>
                    <div class="flex flex-row w-[100%] items-baseline">
                        <label for="businessLon" class="block mb-4 text-lg font-medium text-gray-800">Longitud</label>
                        <input id="businessLon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-[50%] p-2.5 ml-auto mr-20" maxLength={30}
                            value={bLon} onChange={ e => setbLon(e.target.value) }></input>
                    </div>
                </div>
                <div class="mt-5 flex flex-col w-full items-center">
                    { errorCode === 2 && (
                        <div class="bg-red-200 mb-8 w-[50%] text-center rounded-md border border-red-600 h-[20%]">
                            <p class="text-red-600 text-lg text-center">{errorMessage}</p>
                        </div>
                    )}
                    
                    <button
                        type="button"
                        class=" float-right mr-[10%] inline-block rounded bg-green-600 px-6 pb-2 pt-2.5 text-s font-medium 
                        leading-normal text-white shadow-md transition duration-150 ease-in-out 
                        hover:bg-primary-600 hover:shadow-xl"
                        onClick={() => nextStep()}>
                        Siguiente
                    </button>
                </div>
            </div>,
        },
        {
            title: 'Categorias',
            content: 
                <div class="items-center flex flex-col">
                    <div class="w-full items-center flex flex-col">
                        
                        <ComboBox Name="category" Label="Selecciona la Categoria" data={categories} SelectItemCallback={(id) => selectCategory(id)} SelectedText={bCategoryText}/>
                        <ComboBox Name="subcategory" class="mt-2" Label="Selecciona la Sub Categoria" data={subCatArray} SelectItemCallback={(id) => selectSubCategory(id)} SelectedText={bSubCategoryText}/>
                        <div class="mt-5 flex flex-col w-full items-center">
                            { errorCode === 3 && (
                                <div class="bg-red-200 mb-8 w-[50%] text-center rounded-md border border-red-600 h-[20%]">
                                    <p class="text-red-600 text-lg text-center">{errorMessage}</p>
                                </div>
                            )}
                            
                            <button
                                type="button"
                                class=" float-right mr-[10%] inline-block rounded bg-green-600 px-6 pb-2 pt-2.5 text-s font-medium 
                                leading-normal text-white shadow-md transition duration-150 ease-in-out 
                                hover:bg-primary-600 hover:shadow-xl"
                                onClick={() => nextStep()}>
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>,
        },
        {
            title: 'Horario',
            content: 
              <div class="items-center flex flex-col">
                  <div class="w-full items-center flex flex-col">
                      <table>
                        <thead class="dark:border-neutral-500 font-normal text-sm border-b">
                            <tr>
                                <th class="px-6 py-4">Dia</th>
                                <th class="px-6 py-4">Apertura</th>
                                <th class="px-6 py-4">Cierre</th>
                                <th class=""><BsPlusCircle class="h-full w-6 cursor-pointer" onClick={() => togleNewSchedule()}/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bSchedule && bSchedule.sort(compareDay).map((schedule) => {
                                    return(
                                        <tr class="h-10">
                                            <td class="px-6">{weekday[schedule.day]}</td>
                                            <td class="px-6">{schedule.hStart}</td>
                                            <td class="px-7">{schedule.hClose}</td>
                                            <td class="px-10 "><BsTrashFill class="cursor-pointer hover:scale-150" onClick={() => deleteDaySchedule(schedule.day)}/></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                      </table>
                      <div class="mt-5 flex flex-col w-full items-center">
                        { errorCode === 4 && (
                            <div class="bg-red-200 mb-8 w-[50%] text-center rounded-md border border-red-600 h-[20%]">
                                <p class="text-red-600 text-lg text-center">{errorMessage}</p>
                            </div>
                        )}
                        
                        <button
                            type="button"
                            class=" float-right mr-[10%] inline-block rounded bg-green-600 px-6 pb-2 pt-2.5 text-s font-medium 
                            leading-normal text-white shadow-md transition duration-150 ease-in-out 
                            hover:bg-primary-600 hover:shadow-xl"
                            onClick={() => nextStep()}>
                            Siguiente
                        </button>
                    </div>
                  </div>
              </div>,
        },
        {
            title: 'Redes Sociales',
            content: 
                <div class="items-center flex flex-col">
                    <div class="2xl:w-[70%] flex flex-row h-10 items-center">
                        <TbWorldWww class="h-[70%] w-10 mr-2" />
                        <label for="businessWeb" class="block text-lg font-medium p-1 text-gray-800 w-40">Sitio Web</label>
                        <input id="businessWeb" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" maxLength={500}
                            value={bLinks.web} onChange={ e => setLink('web', e.target.value) }></input>                  
                    </div>
                    <div class="2xl:w-[70%] flex flex-row h-10 items-center mt-2">
                        <BsFacebook class="h-[70%] w-10 mr-2" />
                        <label for="businessFacebook" class="block text-lg font-medium p-1 text-gray-800 w-40">Facebook</label>
                        <input id="businessFacebook" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" maxLength={500}
                            value={bLinks.facebook} onChange={ e => setLink('facebook', e.target.value) }></input>                  
                    </div>
                    <div class="2xl:w-[70%] flex flex-row h-10 items-center mt-2">
                        <TbBrandInstagram class="h-[70%] w-10 mr-2" />
                        <label for="businessInstagram" class="block text-lg font-medium p-1 text-gray-800 w-40">Instagram</label>
                        <input id="businessInstagram" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" maxLength={500}
                            value={bLinks.insta} onChange={ e => setLink('instagram', e.target.value) }></input>                  
                    </div>
                    <button
                        type="button"
                        class=" float-right mr-[10%]  mt-5 inline-block rounded bg-green-600 px-6 pb-2 pt-2.5 text-s font-medium 
                        leading-normal text-white shadow-md transition duration-150 ease-in-out 
                        hover:bg-primary-600 hover:shadow-xl"
                        onClick={() => nextStep()}>
                        Siguiente
                    </button>
                </div>,
        },
        {
            title: 'Assets',
            content: 
                <div class="flex flex-col">
                    <div class='w-full text-black font-semibold'>
                        <div class="flex flex-row items-center place-content-evenly border-b-2 mb-10">
                            <div class="w-56 h-56">
                                <ImageUploadPreview Name="logo" Label="Logotipo" AddAssetCallback={saveAsset}/>
                            </div>
                            <div class="w-96 h-64">
                                <ImageUploadPreview Name="background" Label="Foto principal" AddAssetCallback={saveAsset}/>
                            </div>
                        </div>
                        <div class="border-b-2 mb-10">
                            <p class="text-xl mb-4">Imagenes del Banner Principal</p>
                            <div class="flex flex-row items-center place-content-evenly">
                                <div class="w-64 h-64">
                                    <ImageUploadPreview Name="BannerPrincipal1" Label="Imagen 1" AddAssetCallback={saveAsset}/>
                                </div>
                                <div class="w-64 h-64">
                                    <ImageUploadPreview Name="BannerPrincipal2" Label="Imagen 2" AddAssetCallback={saveAsset}/>
                                </div>
                                <div class="w-64 h-64">
                                    <ImageUploadPreview Name="BannerPrincipal3" Label="Imagen 3" AddAssetCallback={saveAsset}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p class="text-xl mb-4">Imagenes del Banner Secundario</p>
                            <div class="flex flex-row items-center place-content-evenly">
                                <div class="w-96 h-64">
                                    <ImageUploadPreview Name="BannerSecond1" Label="Imagen 1" AddAssetCallback={saveAsset}/>
                                </div>
                                <div class="w-96 h-64">
                                    <ImageUploadPreview Name="BannerSecond2" Label="Imagen 2" AddAssetCallback={saveAsset}/>
                                </div>
                                <div class="w-96 h-64">
                                    <ImageUploadPreview Name="BannerSecond3" Label="Imagen 3" AddAssetCallback={saveAsset}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>,
        },
      ];

    const selectCategory = (item) => {
        setbSubCategoryID('');
        setbSubCategoryText('');
        setsubCatArray(null);

        if(!item){
            setbCategoryID('');
            setbCategoryText('');
            return;
        }

        setbCategoryID(item._id);
        setbCategoryText(item.name);

        var subCats = categories.find((category) => category._id === item._id)?.subcategoriesId || [];

        setsubCatArray(subCats);
    }

    const selectSubCategory = (item) => {
        if(!item){
            setbSubCategoryID('');
            setbSubCategoryText('');
            return;
        }

        setbSubCategoryID(item._id);
        setbSubCategoryText(item.name);
    }

    const setLink = (site, link) => {
        setbLinks({...bLinks, [site]:link});
    }

    const togleNewSchedule = () => {
        setIsOpen(!isOpen);
    }

    const addNewSchedule = (schedule) => {
        setbSchedule([...bSchedule, schedule]);
        setIsOpen(false);
    }

    const deleteDaySchedule = (day) => {
        var newSchedule = bSchedule.filter(function(objeto) {
            return objeto.day !== day;
        });
        setbSchedule(newSchedule);
    }

    const saveBusiness = async () => {
        if(isUploadingAssets) return;

        let banners = ['','',''];
        let secondBanners = ['','',''];
        var index = -1;

        setUploadingAssets(true);
        let business = {
            userName: userName,
            name: bName,
            phone: bPhone,
            address: bAddress,
            geolocalization: {lat: bLat, lon:bLon},
            email:bEmail,
            url_fb: bLinks.facebook || '',
            url_insta: bLinks.instagram || '',
            url: bLinks.web || '',
            schedule:bSchedule,
            subcategoriesId: bSubCategoryId,
            pass: pwd
        }
        let uploadingError = false;

        const id = await addBusiness(user.token, business);

        if(id){
            business._id = id;
            business.enabled = true;
            business.logo = '';
            business.background = '';
            business.mainBanner = [];
            business.secondBanner = [];

            for(const key in bAssets){
                if(bAssets.hasOwnProperty(key)){
                    const asset = bAssets[key];

                    const formData = new FormData();
                    formData.append('userName', userName);
                    formData.append('ref', key);
                    formData.append('image', asset, `${userName}_${key}.png`);

                    const res = await uploadBusinessAssets(user.token, formData);
                    
                    if(res){
                        if(key.includes('BannerPrincipal') || key.includes('BannerSecond')){
                            index = GetArrayPositionFromString(key);
                        }

                        if(key === 'logo' || key === 'background'){
                            business[key] = res.name;
                        }
                        else if(key.includes('BannerPrincipal')){
                            if(index !== -1){
                                banners[index] = res.name;
                            }
                        }
                        else if( key.includes('BannerSecond')){
                            if(index !== -1){
                                secondBanners[index] = res.name;
                            }
                        }
                    }
                    else{
                        setErrorAssets([...errorAssets, key]);
                        uploadingError = true;
                    }
                }
            }
            business.mainBanner = banners;
            business.secondBanner = secondBanners;

            await updateBusinessGallery(id, 'mainBanner', banners);
            await updateBusinessGallery(id, 'secondBanner', secondBanners);

            addNewBusiness(business);            

            if(uploadingError){
                setErrorCode(50);
            }
            else{              

                setTimeout(() => {
                    setUploadingAssets(false);
                    navigate('/Business');
                }, 1000);
                
            }
        }
    }

    const updateBusinessGallery = async (id, gallery, data) => {
        const sendData = {
            'id': id,
            'data':data,
            'field':gallery
        }
        const res = await updateBusinessData(user.token, sendData);
        return res;

        // if(res){
        //     const nBusiness = business.map((b => {
        //         if(b._id === id){
        //             return { ...b, name: data };
        //         }
        //         return b;
        //     }));
    
        //     setBusinessData(nBusiness);
        // }        
    }

    const continueBusiness = () => {
        navigate('/Business');
    }

    return (
        <div class="p-5 w-full">
            <div>
                <div class="flex-row h-10 mt-1">
                    <p class="mr-10 font-semibold text-4xl">Agregar un Negocio</p>                    
                </div>
                <div class="mt-10 p-5 flex justify-center items-center flex-col">
                    <div class="w-[90%]">
                    {
                        steps.map((item, index) => (
                            <div key={index} className="mb-1">
                                <div
                                    className="flex justify-between items-center bg-gray-100 rounded-t-lg p-4 cursor-pointer border"
                                    onClick={() => toggleStep(index)}
                                >
                                    <span className="font-semibold">{item.title}</span>
                                    <MdOutlineArrowForwardIos className={`w-6 h-6 transition-transform transform ${
                                        step === index ? 'rotate-90' : ''
                                    }`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d={step === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7-7 7'}
                                        />
                                    </MdOutlineArrowForwardIos>
                            </div>
                                {step === index && (
                                    <div className="bg-white p-4 border-l border-r border-b border-gray-100">
                                    {item.content}                                    
                                    </div>
                                )}
                                
                            </div>
                        ))}
                        <div class="flex flex-col w-full h-20 items-center mt-[60px]">
                            <button
                                type="button"
                                class="bg-green-600 px-6 pb-2 pt-2.5 text-s font-medium rounded mt-5
                                leading-normal text-white shadow-md transition duration-150 ease-in-out 
                                hover:bg-primary-600 hover:shadow-xl"
                                onClick={() => saveBusiness()}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            { isOpen && (
                <SelectScheduleModal Close={togleNewSchedule} Schedule={bSchedule} AddSchedule={addNewSchedule} />
            )}

            { errorCode === 50 && (
                    <UploadAssetModalResult callBack={continueBusiness} />
            )}

            { isUploadingAssets && (
                <Loading fullScreen={true} loader={false} message="Guardando..."/>
            )}
        </div>
    );
};

export default AddBusiness;