import { ASSETS_URL } from "./constants";

const FormatDate = (stringDate) => {
    const date = new Date(stringDate);
    const options = { year: 'numeric', month: 'long', day:'numeric' };

    return date.toLocaleDateString(undefined, options);
}

const GetArrayPositionFromString = (str) => {
    var pString = str.match(/\d+/);
    if(pString !== null){
        return parseInt(pString[0] - 1);
    }
    else return -1;
}

const weekday = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"];
const compareDay = (a, b) => { return a.day - b.day; }

const GetBusinessAssetURL = (bId, assetName) =>{
    if(!assetName || assetName === "" ) return null;

    return ASSETS_URL + bId + "/" + assetName;
}

const validateString = (str, isAlpha = false) => {
    var regex = /^[a-zA-Zá-úÁ-Ú]+(?:\s[a-zA-Zá-úÁ-Ú]+)*$/;
    
    if(isAlpha) regex = /^[a-zA-Zá-úÁ-Ú0-9%+#-]+(?:\s[a-zA-Zá-úÁ-Ú0-9%+#-]+)*$/;

    return regex.test(str);
}

export { FormatDate, GetArrayPositionFromString, weekday, compareDay, GetBusinessAssetURL, validateString };