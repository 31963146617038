import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { GoHome, GoOrganization, GoListUnordered } from 'react-icons/go';

import { VERSION } from '../constants';

import logo from '../assets/ziti_icon.png';
import logo_ziti from '../assets/Logo_Ziti.webp';

const menuLinks = [
    { label: "Inicio", link: "/", icon: <GoHome class="float-left h-full lg:mr-6 lg:ml-4" size="2em"/> },
    { label: "Negocios", link: "/Business", icon: <GoOrganization class="float-left h-full lg:mr-6 lg:ml-4" size="2em"/> },
    { label: "Categorias", link: "/Categories", icon: <GoListUnordered class="float-left h-full lg:mr-6 lg:ml-4" size="2em"/> }
]

const Layout = () => {
    return (
        <div class="flex flex-col sm:flex-row w-full ">
            <div class="outer-div relative sm:justify-center sm:items-center sm:w-20 lg:w-3/12">
                <div class="flex flex-col items-start sm:items-center sm:mt-20">
                    <img src={logo_ziti} class="cursor-pointer peer h-12 lg:h-32" alt="ZiTi" />
                </div>
                <div className='block sm:relative'>
                    <div class="mt-0 sm:mt-10">
                        <nav class="w-full ">
                            <ul>
                                {
                                    menuLinks.map((menu) => {
                                        return(
                                            <li className="menuLink">
                                                <NavLink to={menu.link}>
                                                    <div id="menuContainer" class="h-full flex flex-row cursor-pointer group justify-center">
                                                        <div id="marker" class="h-full w-2 lg:w-2 block sm:hidden lg:block float-left"></div>
                                                        <div id="item" class="flex-row h-full flex w-full sm:w-16 lg:w-full mr-4 sm:mr-0 lg:mr-6 sm:ml-0 lg:ml-4 ml-4 sm:items-center lg:items-start lg:justify-normal sm:justify-center">
                                                            {menu.icon}
                                                            <div class="flex items-center h-full mr-4 lg:flex lg:relative sm:hidden sm:group-hover:flex sm:absolute sm:left-20 lg:left-0">
                                                                <p class="text-center">{menu.label}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                    <div class="flex flex-row w-full h-10 items-center text-center mt-32">
                        <p class="text-center w-full text-gray-300 italic">v{VERSION}</p>
                    </div>
                </div>
            </div>
            <div class="hidden sm:inline-block h-4/5 min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"></div>
            <Outlet />
        </div>
    );
};

export default Layout;