import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useUser } from '../context/UserContext.js';
import { useCategories } from '../context/CategoriesContext.js';
import { useBusiness } from '../context/BusinessContext.js';

import ActionButton from '../components/ActionButton.js';

import { loginServer, fetchCategories } from '../services/ziti.js';
import { fetchBusiness } from '../services/ziti.business.js';


import logo from '../assets/ziti_icon.png';
import logo_ziti from '../assets/Logo_Ziti.webp';

const Login = () => {
    const [userName, setUserName] = useState();
    const [pwd, setPwd] = useState();
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();

    const { login } = useUser();
    const { setIsLoadingCategories, setCategoriesData } = useCategories();
    const { setLoadingBusiness, setBusinessData } = useBusiness();

    // const query = useCategory();

    const HandleLoginSubmit = async () => {
        if(isLoading) return;

        setIsLoading(true);
        setError(false);

        var loginResponse = await loginServer(userName, pwd);

        setIsLoading(false);

        if(loginResponse){
            setUserName('');
            setPwd('');
            
            const user = {
                'token': loginResponse.token,
                ...loginResponse.data,            
            }

            login(user);
            setIsLoadingCategories(true);

           await fetchCategories(user.token, (data) => {
                setCategoriesData(data);
                setIsLoadingCategories(false);
            });
            

            setLoadingBusiness(true);
            await fetchBusiness(user.token, (data) => {
                setBusinessData(data);
                setLoadingBusiness(false);
            })
        }
        else{
            setError(true);
        }
    }

    return (
        <div class="bg-white h-screen w-screen outer-div flex justify-center items-center">
            <div class="inner-div w-[70%] 2xl:w-[17.5%] xl:w-[20%] lg:w-[25%] md:w-[30%] sm:w-[50%] h-auto flex flex-col items-center">
                <img src={logo_ziti} class="h-60" alt="ZiTi" />
                <div class="w-full mt-16">
                    <input
                        type="text"
                        class="peer block min-h-[auto] w-full rounded border-slate-950/50 border 
                        bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all 
                        duration-200 ease-linear focus:placeholder:opacity-30 data-[te-input-state-active]:placeholder:opacity-100 
                        motion-reduce:transition-none text-black placeholder:text-black"
                        id="inputUser"
                        onChange={ e => setUserName(e.target.value) }
                        placeholder="Usuario" />
                </div>
                <div class=" w-full mt-10">
                <input
                    type="password"
                    class="peer block min-h-[auto] w-full rounded border-slate-950/50 border 
                    bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all 
                    duration-200 ease-linear focus:placeholder:opacity-30 data-[te-input-state-active]:placeholder:opacity-100 
                    motion-reduce:transition-none text-black placeholder:text-black"
                    id="exampleFormControlInput22"
                    onChange={ e => setPwd(e.target.value) }
                    placeholder="Contraseña" />
                </div>
                {error && (
                    <div className='mt-5 bg-red-200 border-2 border-red-600 rounded p-2 text-md w-full justify-center content-center text-center text-red-600'>
                        <p>Error al iniciar sesion.</p>
                    </div>
                )}
                <ActionButton Label="Entrar" ClickCallback={() => HandleLoginSubmit(userName, pwd)} Loading={isLoading}/>
                {/* <button class="mt-12 bg-yellow-500 w-3/4 h-12 text-white rounded text-2xl font-semibold shadow-xl" type="button" onClick={() => HandleLoginSubmit(userName, pwd)}>Entrar</button> */}
            </div>

        </div>
    );
};

Login.propTypes = {
}

export default Login;